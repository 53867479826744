import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO
      title="Salesforce Developer - Careers at D2i Technology"
      description="Join our team as a Salesforce Developer. 4-5 years of experience required in building and deploying products to Salesforce AppExchange."
    />
    <section id="career-detail" className="career-detail">
      <div className="container" data-aos-todo="fade-up">
        <div className="section-title">
          <h1>Salesforce Developer</h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Salesforce Developer
                </li>
              </ol>
            </nav>
            <div className="job-card">
              <div className="job-header">
                <h2>Job Description</h2>
                <Link
                  to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Apply Now
                </Link>
              </div>
              <p>
                We are seeking an experienced Salesforce Developer to join our
                dynamic team. The ideal candidate will have a strong background
                in building and deploying products to Salesforce AppExchange.
              </p>

              <h3>Requirements:</h3>
              <ul>
                <li>4-5 years of experience in Salesforce development</li>
                <li>
                  Proficiency in building and deploying products to Salesforce
                  AppExchange
                </li>
                <li>
                  Strong understanding of Salesforce platform capabilities and
                  limitations
                </li>
                <li>
                  Experience with Apex, Visualforce, and Lightning Web
                  Components
                </li>
                <li>
                  Familiarity with Salesforce APIs and integration patterns
                </li>
                <li>
                  Knowledge of Salesforce security model and best practices
                </li>
              </ul>

              <h3>Responsibilities:</h3>
              <ul>
                <li>Design, develop, and maintain Salesforce applications</li>
                <li>
                  Collaborate with cross-functional teams to gather requirements
                  and implement solutions
                </li>
                <li>Ensure code quality through testing and code reviews</li>
                <li>Troubleshoot and resolve technical issues</li>
                <li>
                  Stay up-to-date with Salesforce platform updates and best
                  practices
                </li>
              </ul>

              <h3>What We Offer:</h3>
              <ul>
                <li>Competitive salary and benefits package</li>
                <li>Opportunities for professional growth and development</li>
                <li>Collaborative and innovative work environment</li>
                <li>Chance to work on exciting projects for diverse clients</li>
              </ul>
            </div>
            <div className="apply-footer">
              <p>Ready to take the next step in your career?</p>
              <Link
                to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                target="_blank"
                className="btn btn-primary"
              >
                Apply for this Position
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
